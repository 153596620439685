import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: null,
  identity: null,
};

const { actions, reducer } = createSlice({
  name: "userIdentity",
  initialState,
  reducers: {
    resetError(state) {
      state.error = null;
    },
    resetState(state) {
      state.error = initialState.error;
      state.identity = initialState.identity;
    },

    updateUserIdentity(state, action) {
      state.identity = action.payload;
    },
  },
});

export const {
  resetError,
  resetState,

  updateUserIdentity,
} = actions;

export const resetIdentityState = () => async (dispatch) => {
  dispatch(resetState());
};

export default reducer;
