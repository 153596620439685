import { createSlice } from "@reduxjs/toolkit";

import { sendRequest } from "./auth";
import { updateUserIdentity } from "./userIdentity";
import { updateUserSuccess } from "./userInfo"; // eslint-disable-line

const initialState = {
  isLoading: false,
  error: null,
  company: [],
};

const startLoading = (state) => {
  state.isLoading = true;
  state.error = null;
};

const loadingFailed = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

const { actions, reducer } = createSlice({
  name: "userCompany",
  initialState,
  reducers: {
    resetError(state) {
      state.error = null;
    },
    resetState(state) {
      state.error = initialState.error;
      state.company = initialState.company;
      state.isLoading = initialState.isLoading;
    },

    updateCompanyRequest: startLoading,
    updateCompanySuccess(state, action) {
      state.company =
        action.payload && action.payload.id ? [action.payload] : [];
      state.isLoading = false;
    },
    updateCompanyFailure: loadingFailed,
  },
});

export const {
  resetError,
  resetState,

  updateCompanyRequest,
  updateCompanySuccess,
  updateCompanyFailure,
} = actions;

export const updateTemporaryCompanyData = (data) => {
  sessionStorage.setItem(
    "temporaryCompanyData",
    JSON.stringify({
      companyName: data.companyName,
      country: data.country,
      city: data.city,
      streetName: data.streetName,
      vatIdentification: data.vatIdentification,
      zipcode: data.zipcode,
      houseNumber: data.houseNumber,
      housenumberAdditive: data.housenumberAdditive,
    })
  );
  // sessionStorage.setItem('temporaryCompanyLogo', JSON.stringify({
  //   temporaryName: data.temporaryName,
  //   temporaryLogo: data.temporaryLogo,
  // }));
};

export const updateCompany = (companyData) => async (dispatch, getState) => {
  try {
    dispatch(updateCompanyRequest());
    const {
      userInfo: { currentUser },
      // eslint-disable-next-line no-unused-vars
      userCompany: { company },
    } = getState();

    const {
      companyName,
      country,
      city,
      streetName,
      // logo,
      vatIdentification,
      zipcode,
      houseNumber,
      housenumberAdditive,
    } = companyData;

    // let picture;
    // if (logo) {
    //   const body = new FormData();
    //   body.append('files', logo);
    //   picture = await dispatch(sendRequest('post', '/upload', body));
    // }

    const responseUser = await dispatch(
      sendRequest("put", `/users/${currentUser.id}`, {
        company: {
          name: companyName,
          vatNumber: vatIdentification,
          // logo: logo ? picture.data[0] : company[0].logo,
          address: {
            country,
            city,
            zipcode,
            streetName,
            houseNumber,
            houseNumberAdditive: housenumberAdditive,
          },
        },
      })
    );
    dispatch(updateCompanySuccess(responseUser.data.company));
    dispatch(updateUserIdentity(responseUser.data.identity));
    dispatch(updateUserSuccess(responseUser.data));
    // sessionStorage.removeItem('temporaryCompanyData');
    // sessionStorage.removeItem('temporaryCompanyLogo');
  } catch (error) {
    dispatch(updateCompanyFailure(error));
  }
};

export const resetCompanyState = () => async (dispatch) => {
  dispatch(resetState());
};

export default reducer;
