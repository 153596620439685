import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { combineReducers } from "redux";
import { createWrapper } from "next-redux-wrapper";
import { CookieStorage } from "redux-persist-cookie-storage";
import Cookies from "js-cookie";

import authReducer from "./auth";
import userInfoReducer from "./userInfo";
import userIdentityReducer from "./userIdentity";
import userCompanyReducer from "./userCompany";
import notificationReducer from "./notification";
import iDINReducer from "./iDIN";

const makeConfiguredStore = (reducer) =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "userInfo/signInFailure",
          "userInfo/registerFailure",
          "userInfo/resendEmailFailure",
          "userInfo/getCurrentUserFailure",
          "userInfo/forgotPasswordFailure",
          "userInfo/resetPasswordFailure",
          "userInfo/updateUserFailure",
          "userInfo/updateBiddingConfirmationFailure",
          "userInfo/updateEmailNotificationFailure",
          "iDIN/getBanksFailure",
          "iDIN/getTransactionFailure",
          "iDIN/getStatusFailure",
          "userCompany/updateCompanyFailure",
        ],
      },
    }),
  });

const makeStore = () => {
  const isServer = typeof window === "undefined";

  if (isServer) {
    const combinedReducer = combineReducers({
      auth: authReducer,
      userInfo: userInfoReducer,
      userIdentity: userIdentityReducer,
      userCompany: userCompanyReducer,
      iDIN: iDINReducer,
    });
    return makeConfiguredStore(combinedReducer);
  } else {
    // eslint-disable-line
    // we need it only on client side
    const { persistStore, persistReducer } = require("redux-persist"); // eslint-disable-line
    const storage = require("redux-persist/lib/storage/session").default; // eslint-disable-line

    const persistConfigToken = {
      key: "auth",
      storage: new CookieStorage(Cookies),
    };

    const persistConfigUserInfo = {
      key: "userInfo",
      blacklist: ["error"],
      storage: new CookieStorage(Cookies),
    };

    const persistConfigUserIdentity = {
      key: "userIdentity",
      blacklist: ["error"],
      storage: new CookieStorage(Cookies),
    };

    const persistConfigUserCompany = {
      key: "userCompany",
      blacklist: ["error"],
      storage: new CookieStorage(Cookies),
    };

    const persistConfigIDIN = {
      key: "iDIN",
      blacklist: ["error"],
      storage, // : new CookieStorage(Cookies),
    };

    const persistConfigNotification = {
      key: "notification",
      blacklist: ["error"],
      storage, // : new CookieStorage(Cookies),
    };

    const combinedReducer = combineReducers({
      auth: persistReducer(persistConfigToken, authReducer),
      userInfo: persistReducer(persistConfigUserInfo, userInfoReducer),
      userIdentity: persistReducer(
        persistConfigUserIdentity,
        userIdentityReducer
      ),
      userCompany: persistReducer(persistConfigUserCompany, userCompanyReducer),
      iDIN: persistReducer(persistConfigIDIN, iDINReducer),
      notification: persistReducer(
        persistConfigNotification,
        notificationReducer
      ),
    });

    // const persistedReducer = persistCombineReducers(persistConfig, combinedReducer);
    const store = makeConfiguredStore(combinedReducer);

    // eslint-disable-next-line no-underscore-dangle
    store.__persistor = persistStore(store); // Nasty hack

    return store;
  }
};

export const wrapper = createWrapper(makeStore); // eslint-disable-line
