import { createSlice } from "@reduxjs/toolkit";

import { sendRequest } from "./auth";
import { updateUserIdentity } from "./userIdentity";
import { emailConfirmationReset } from "./userInfo";

const initialState = {
  isLoading: false,
  error: null,
  bankList: [],
  transaction: {},
  status: {},
};

const startLoading = (state) => {
  state.isLoading = true;
  state.error = null;
};

const loadingFailed = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

const { actions, reducer } = createSlice({
  name: "iDIN",
  initialState,
  reducers: {
    resetError(state) {
      state.error = null;
    },
    resetState(state) {
      state.isLoading = initialState.isLoading;
      state.error = initialState.error;
      state.bankList = initialState.bankList;
      state.transaction = initialState.transaction;
      state.status = initialState.status;
    },

    getBanksRequest: startLoading,
    getBanksSuccess(state, action) {
      state.bankList = action.payload;
      state.isLoading = false;
    },
    getBanksFailure: loadingFailed,

    getTransactionRequest: startLoading,
    getTransactionSuccess(state, action) {
      const data = { ...action.payload };
      state.transaction = { ...data };
      state.status = initialState.status;
      state.isLoading = false;
    },
    getTransactionFailure: loadingFailed,

    resetIdinTransaction(state) {
      state.transaction = initialState.transaction;
    },

    getStatusRequest: startLoading,
    getStatusSuccess(state, action) {
      const data = { ...action.payload };
      state.status = { ...data };
      state.isLoading = false;
    },
    getStatusFailure: loadingFailed,

    resetIdinStatus(state) {
      state.status = initialState.status;
    },
  },
});

export const {
  resetError,
  resetState,

  getBanksRequest,
  getBanksSuccess,
  getBanksFailure,

  getTransactionRequest,
  getTransactionSuccess,
  getTransactionFailure,

  resetIdinTransaction,

  getStatusRequest,
  getStatusSuccess,
  getStatusFailure,

  resetIdinStatus,
} = actions;

export const getBanks = () => async (dispatch) => {
  try {
    dispatch(getBanksRequest());
    const url = "/banks";
    const payload = { merchant_token: process.env.NEXT_PUBLIC_IDIN_TOKEN };

    const response = await dispatch(sendRequest("post", url, payload));

    dispatch(getBanksSuccess(response.data));
  } catch (error) {
    dispatch(getBanksFailure(error));
  }
};

export const getTransaction =
  (valueBank, entranceCode, href) => async (dispatch) => {
    try {
      dispatch(getTransactionRequest());
      const url = "/transaction";
      const payload = {
        merchant_token: process.env.NEXT_PUBLIC_IDIN_TOKEN,
        identity: true,
        name: true,
        gender: true,
        address: true,
        date_of_birth: true,
        "18y_or_older": true,
        email_address: true,
        telephone_number: true,
        issuer_id: valueBank[1],
        entrance_code: entranceCode,
        merchant_return_url: href.split("?")[0],
        language: "en",
      };

      const response = await dispatch(sendRequest("post", url, payload));
      dispatch(
        getTransactionSuccess({
          ...response.data,
          valueBank,
          entrance_code: entranceCode,
        })
      );
      window.location.replace(response.data.issuer_authentication_url);
    } catch (error) {
      dispatch(getTransactionFailure(error));
    }
  };

export const resetTransaction = () => resetIdinTransaction();

export const getStatus = () => async (dispatch, getState) => {
  try {
    dispatch(getStatusRequest());
    const {
      iDIN,
      userInfo: { currentUser },
    } = getState();
    const url = "/status";
    const payload = {
      merchant_token: process.env.NEXT_PUBLIC_IDIN_TOKEN,
      transaction_id: iDIN.transaction.transaction_id,
      merchant_reference: iDIN.transaction.merchant_reference,
      userId: currentUser.id,
    };

    const response = await dispatch(sendRequest("post", url, payload));
    if (response.data.status) {
      dispatch(getStatusSuccess(response.data));
      dispatch(emailConfirmationReset())
    } else {
      dispatch(getStatusSuccess({ status: "success" }));
      dispatch(emailConfirmationReset())
      dispatch(updateUserIdentity(response.data.identity));
    }
  } catch (error) {
    dispatch(getStatusFailure(error));
  }
};

export const resetStatus = () => resetIdinStatus();

export const resetIDINState = () => async (dispatch) => {
  dispatch(resetState());
};

export default reducer;
