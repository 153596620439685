import { createSlice } from "@reduxjs/toolkit";

import apiClient from "../constants/apiClient";
import { sendRequest, setToken } from "./auth";
import { updateUserIdentity } from "./userIdentity";
import { updateCompanySuccess } from "./userCompany"; // eslint-disable-line

const initialState = {
  currentUser: null,
  isLoading: false,
  isSignIn: false,
  is2FAEnabled: false,
  errorMsgSignIn: false,
  errorMsgRegistration: false,
  errorMsgResendEmail: false,
  errorMsgResetPassword: false,
  errorMsgNewPassword: false,
  errorMsg2FA: false,
  error: null,
  userHasRegistered: false,
  confirmationEmailResended: false,
  emailLinkConfirmed: false,
  restoreEmail: false,
  isViewer: false,
  nextPath: null,
};

const startLoading = (state) => {
  state.error = null;
  state.isLoading = true;
};

const loadingFailed = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

const { actions, reducer } = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    resetError(state) {
      state.error = null;
    },
    resetState(state) {
      state.currentUser = initialState.currentUser;
      state.isLoading = initialState.isLoading;
      state.isSignIn = initialState.isSignIn;
      state.is2FAEnabled = initialState.is2FAEnabled;
      state.errorMsgSignIn = initialState.errorMsgSignIn;
      state.errorMsgRegistration = initialState.errorMsgRegistration;
      state.errorMsgResendEmail = initialState.errorMsgResendEmail;
      state.errorMsgResetPassword = initialState.errorMsgResetPassword;
      state.errorMsgNewPassword = initialState.errorMsgNewPassword;
      state.error = initialState.error;
      state.userHasRegistered = initialState.userHasRegistered;
      state.confirmationEmailResended = initialState.confirmationEmailResended;
      state.emailLinkConfirmed = initialState.emailLinkConfirmed;
      state.restoreEmail = initialState.restoreEmail;
      state.isViewer = initialState.isViewer;
      state.nextPath = initialState.nextPath;
    },

    resetErrorStatus(state) {
      state.error = state.error?.message;
      state.errorMsgSignIn = initialState.errorMsgSignIn;
      state.errorMsgRegistration = initialState.errorMsgRegistration;
      state.errorMsgResendEmail = initialState.errorMsgResendEmail;
      state.errorMsgResetPassword = initialState.errorMsgResetPassword;
      state.errorMsgNewPassword = initialState.errorMsgNewPassword;
      state.userHasRegistered = initialState.userHasRegistered;
      state.confirmationEmailResended = initialState.confirmationEmailResended;
      state.restoreEmail = initialState.restoreEmail;
    },

    emailConfirmationSuccess(state) {
      state.emailLinkConfirmed = true;
    },
    emailConfirmationReset(state) {
      state.emailLinkConfirmed = initialState.emailLinkConfirmed;
    },

    registerRequest(state) {
      state.errorMsgRegistration = initialState.errorMsgRegistration;
    },
    registerSuccess(state, action) {
      const user = { ...action.payload };
      if (user && user.id) {
        state.userHasRegistered = true;
      }
    },
    registerFailure(state) {
      state.errorMsgRegistration = true;
    },

    resendEmailRequest(state) {
      state.errorMsgResendEmail = initialState.errorMsgResendEmail;
    },
    resendEmailSuccess(state, action) {
      state.confirmationEmailResended = action.payload;
    },
    resendEmailFailure(state) {
      state.errorMsgResendEmail = true;
    },

    enableSignInWith2FA(state, action) {
      state.is2FAEnabled = true;
      state.isLoading = false;
      state.error = null;
      state.errorMsg2FA = false;
      state.currentUser = {
        ...action.payload,
        identity: null,
        company: [],
        passportPhotos:
          action.payload?.passportPhotos?.map((photo) => ({
            id: photo.id,
            name: photo.name,
            url: photo.url,
          })) || [],
      };
    },

    signInRequest(state) {
      state.errorMsgSignIn = initialState.errorMsgSignIn;
      state.isLoading = true;
      state.error = null;
    },

    signInWith2FARequest(state) {
      state.errorMsg2FA = initialState.errorMsg2FA;
      state.isLoading = true;
      state.error = null;
    },

    getCurrentUserRequest: startLoading,

    signInSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.isSignIn = true;
      state.errorMsgSignIn = false;

      if (action.payload?.role?.permissions) {
        delete action.payload?.role?.permissions;
      }

      state.currentUser = {
        ...action.payload,
        identity: action.payload.identity,
        company: [action.payload.company],
        passportPhotos:
          action.payload?.passportPhotos?.map((photo) => ({
            id: photo.id,
            name: photo.name,
            url: photo.url,
          })) || [],
      };
    },

    signInWith2FASuccess(state, action) {
      state.is2FAEnabled = false;
      state.isLoading = false;
      state.error = null;
      state.isSignIn = true;
      state.errorMsg2FA = false;

      if (action.payload?.role?.permissions) {
        delete action.payload?.role?.permissions;
      }

      state.currentUser = {
        ...action.payload,
        identity: action.payload.identity,
        company: [action.payload.company],
        passportPhotos:
          action.payload?.passportPhotos?.map((photo) => ({
            id: photo.id,
            name: photo.name,
            url: photo.url,
          })) || [],
      };
    },

    getCurrentUserSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.currentUser = {
        ...action.payload,
        identity: null,
        company: [],
        passportPhotos: action.payload.passportPhotos.map((photo) => ({
          id: photo.id,
          name: photo.name,
          url: photo.url,
        })),
      };
    },

    addUserFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    updatePersonalProfileRequest: startLoading,
    updatePersonalProfileFailure: loadingFailed,
    updatePersonalProfileSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.currentUser = {
        ...action.payload,
        identity: null,
        company: [],
        passportPhotos: action.payload.passportPhotos.map((photo) => ({
          id: photo.id,
          name: photo.name,
          url: photo.url,
        })),
      };
    },
    signInFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.errorMsgSignIn = true;
    },

    signInWith2FAFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.errorMsg2FA = true;
    },

    getCurrentUserFailure: loadingFailed,
    logoutFailure: loadingFailed,

    forgotPasswordRequest(state) {
      state.isLoading = true;
      state.error = null;
      state.restoreEmail = false;
    },
    forgotPasswordSuccess(state) {
      state.isLoading = false;
      state.error = null;
      state.restoreEmail = true;
      state.errorMsgResetPassword = initialState.errorMsgResetPassword;
    },
    forgotPasswordFailure(state) {
      state.isLoading = false;
      state.restoreEmail = false;
      state.errorMsgResetPassword = true;
    },

    resetPasswordRequest(state) {
      state.restoreEmail = initialState.restoreEmail;
      state.errorMsgNewPassword = initialState.errorMsgNewPassword;
      state.error = null;
    },
    resetPasswordFailure(state) {
      state.errorMsgNewPassword = true;
    },

    updateUserRequest: startLoading,
    updateUserSuccess(state, action) {
      state.currentUser = {
        ...action.payload,
        identity: null,
        company: [],
        passportPhotos:
          typeof action.payload.passportPhotos !== "undefined" &&
          action.payload.passportPhotos > 0 &&
          action.payload.passportPhotos.map((photo) => ({
            id: photo.id,
            name: photo.name,
            url: photo.url,
          })),
      };
      state.isLoading = false;
    },
    updateUserFailure: loadingFailed,

    setViewerStatus(state, action) {
      state.isViewer = action.payload.flag;
      state.nextPath = action.payload.nextPath || null;
      state.errorMsgSignIn = initialState.errorMsgSignIn;
      state.errorMsgRegistration = initialState.errorMsgRegistration;
      state.errorMsgResetPassword = initialState.errorMsgResetPassword;
      state.errorMsgNewPassword = initialState.errorMsgNewPassword;
      state.error = initialState.error;
    },

    updatePreferredLanguageRequest: startLoading,
    updatePreferredLanguageSuccess(state, action) {
      state.currentUser = {
        ...state.currentUser,
        defaultLanguage: action.payload,
      };
      state.isLoading = false;
    },
    updatePreferredLanguageFailure: loadingFailed,

    updateBiddingConfirmationRequest: startLoading,
    updateBiddingConfirmationSuccess(state, action) {
      state.currentUser = {
        ...state.currentUser,
        biddingConfirmation: action.payload,
      };
      state.isLoading = false;
    },
    updateBiddingConfirmationFailure: loadingFailed,

    updateEmailNotificationRequest: startLoading,
    updateEmailNotificationSuccess(state, action) {
      state.currentUser = {
        ...state.currentUser,
        emailNotification: action.payload,
      };
      state.isLoading = false;
    },
    updateEmailNotificationFailure: loadingFailed,

    uploadPassportRequest: startLoading,
    uploadPassportSuccess(state, action) {
      state.currentUser = {
        ...state.currentUser,
        passportPhotos: action.payload.picture.map((photo) => ({
          id: photo.id,
          name: photo.name,
          url: photo.url,
        })),
        userStatus: action.payload.response.userStatus,
      };
      state.isLoading = false;
    },
    uploadPassportFailure: loadingFailed,

    updatePassportStatusRequest: startLoading,
    updatePassportStatusSuccess(state) {
      state.isLoading = false;
    },
    updatePassportStatusFailure: loadingFailed,
  },
});

export const {
  resetError,
  resetState,
  enableSignInWith2FA,
  signInRequest,
  signInWith2FARequest,
  getCurrentUserRequest,

  resetErrorStatus,

  emailConfirmationSuccess,
  emailConfirmationReset,

  registerRequest,
  registerSuccess,
  registerFailure,

  resendEmailRequest,
  resendEmailSuccess,
  resendEmailFailure,

  signInSuccess,
  signInWith2FASuccess,
  getCurrentUserSuccess,

  signInFailure,
  signInWith2FAFailure,
  getCurrentUserFailure,
  logoutFailure,

  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,

  resetPasswordRequest,
  resetPasswordFailure,

  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,

  setViewerStatus,

  updatePreferredLanguageRequest,
  updatePreferredLanguageSuccess,
  updatePreferredLanguageFailure,

  updateBiddingConfirmationRequest,
  updateBiddingConfirmationSuccess,
  updateBiddingConfirmationFailure,

  updateEmailNotificationRequest,
  updateEmailNotificationSuccess,
  updateEmailNotificationFailure,

  uploadPassportRequest,
  uploadPassportSuccess,
  uploadPassportFailure,

  updatePassportStatusRequest,
  updatePassportStatusSuccess,
  updatePassportStatusFailure,
} = actions;

export const viewerStatus = (flag, nextPath) =>
  setViewerStatus({ flag, nextPath });

export const closeErrorAlert = () => resetErrorStatus();

export const signIn = (identifier, password) => async (dispatch) => {
  try {
    dispatch(signInRequest());
    const payload = {
      identifier,
      password,
    };

    const response = await apiClient.post("/auth/local/login", payload);

    if (response.data.user.twoFAEnabled) {
      dispatch(enableSignInWith2FA(response.data.user));
    } else {
      dispatch(setToken(response.data.jwt));
      dispatch(updateUserIdentity(response.data.user.identity));
      dispatch(updateCompanySuccess(response.data.user.company));
      dispatch(signInSuccess(response.data.user));
    }
  } catch (error) {
    dispatch(signInFailure());
  }
};

export const signInWith2FA =
  (id, code, isCancelled = false) =>
  async (dispatch) => {
    try {
      dispatch(signInWith2FARequest());
      const payload = {
        id,
        code,
        isLogin: true,
      };

      if (isCancelled) throw new Error("Cancelled");

      const response = await apiClient.post("/verifyToken", payload);

      const { data, error } = response;

      if (error) throw new Error(error);

      const { message, user, jwt } = data;

      if (message && message.includes("Invalid")) throw new Error(message);

      if (!user || !jwt) throw new Error("Invalid user or token");

      dispatch(setToken(response.data.jwt));
      dispatch(updateUserIdentity(response.data.user.identity));
      dispatch(updateCompanySuccess(response.data.user.company));
      dispatch(signInWith2FASuccess(response.data.user));
    } catch (error) {
      dispatch(signInWith2FAFailure(error));
    }
  };

export const register = (email, password) => async (dispatch) => {
  try {
    dispatch(registerRequest());
    const payload = {
      email,
      password,
      role: "authenticated", // hardcoded value for now
    };

    const response = await apiClient.post("/auth/local/register", payload);

    dispatch(registerSuccess(response.data.user));
  } catch (error) {
    dispatch(registerFailure(error));
  }
};

export const signUpNewsletter = (email) => async (dispatch) => {
  try {
    dispatch(registerRequest());
    const payload = {
      email,
      role: "Public", // Viewer role
      signUpNewsletter: true,
    };

    const res = await apiClient.post("/auth/local/register", payload);
    return res.status >= 200 && res.status < 300;
  } catch (error) {
    dispatch(registerFailure(error));
    return false;
  }
};

export const resendEmail = (email) => async (dispatch) => {
  try {
    dispatch(resendEmailRequest());
    const response = await apiClient.post("/auth/send-email-confirmation", {
      email,
    });
    dispatch(resendEmailSuccess(response.data.sent));
  } catch (error) {
    dispatch(resendEmailFailure(error));
  }
};

export const emailConfirmation = (confirmation) => async (dispatch) => {
  try {
    const response = await apiClient.post("/emailConfirmationLogin", {
      confirmation,
    });
    dispatch(setToken(response.data.jwt));
    dispatch(updateUserIdentity(response.data.user.identity));
    dispatch(updateCompanySuccess(response.data.user.company));
    dispatch(signInSuccess(response.data.user));
    dispatch(emailConfirmationSuccess());
  } catch (error) {
    // console.log("error => ", error);
  }
};

export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch(forgotPasswordRequest());
    const payload = { email };

    const response = await apiClient.post("/auth/forgot-password", payload);

    if (response.data.status !== 200) throw new Error(response.data.message);

    dispatch(forgotPasswordSuccess(response));
  } catch (error) {
    dispatch(forgotPasswordFailure(error));
  }
};

export const resetPassword = (code, password) => async (dispatch) => {
  try {
    dispatch(resetPasswordRequest());
    const payload = {
      code,
      password,
      passwordConfirmation: password,
    };

    const response = await apiClient.post("/auth/reset-password", payload);
    dispatch(setToken(response.data.jwt));
    dispatch(updateUserIdentity(response.data.user.identity));
    dispatch(updateCompanySuccess(response.data.user.company));
    dispatch(signInSuccess(response.data.user));
  } catch (error) {
    dispatch(resetPasswordFailure(error));
  }
};

export const updateUser = (userData) => async (dispatch, getState) => {
  try {
    dispatch(updateUserRequest());
    const {
      userInfo: { currentUser },
    } = getState();

    const {
      name,
      dateOfBirth,
      country,
      city,
      streetName,
      surname,
      telephoneNumber,
      zipcode,
      houseNumber,
      houseNumberAdditive,
    } = userData;

    const payloadUser = {
      name,
      // in this case it works correctly
      dateOfBirth,
      telephoneNumber,
      surname,
      address: {
        country,
        city,
        zipcode,
        streetName,
        houseNumber,
        houseNumberAdditive,
      },
    };

    const responseUser = await dispatch(
      sendRequest("put", `/users/${currentUser.id}`, payloadUser)
    );

    dispatch(updateUserSuccess(responseUser.data));
    dispatch(updateUserIdentity(responseUser.data.identity));
    dispatch(updateCompanySuccess(responseUser.data.company));
    dispatch(emailConfirmationReset());
  } catch (error) {
    dispatch(updateUserFailure(error));
  }
};

export const updatePreferredLanguage =
  (defaultLanguage) => async (dispatch, getState) => {
    try {
      dispatch(updatePreferredLanguageRequest());
      const {
        userInfo: { currentUser },
      } = getState();

      const responseUser = await dispatch(
        sendRequest("put", `/users/${currentUser.id}`, { defaultLanguage })
      );
      dispatch(
        updatePreferredLanguageSuccess(responseUser.data.defaultLanguage)
      );
    } catch (error) {
      dispatch(updatePreferredLanguageFailure(error));
    }
  };

export const updateBiddingConfirmation =
  (userData) => async (dispatch, getState) => {
    try {
      dispatch(updateBiddingConfirmationRequest());
      const {
        userInfo: { currentUser },
      } = getState();

      const { biddingConfirmation } = userData;

      const responseUser = await dispatch(
        sendRequest("put", `/users/${currentUser.id}`, { biddingConfirmation })
      );

      dispatch(
        updateBiddingConfirmationSuccess(responseUser.data.biddingConfirmation)
      );
    } catch (error) {
      dispatch(updateBiddingConfirmationFailure(error));
    }
  };

export const updateEmailNotification =
  (userData) => async (dispatch, getState) => {
    try {
      dispatch(updateEmailNotificationRequest());
      const {
        userInfo: { currentUser },
      } = getState();

      const { emailNotification } = userData;

      const responseUser = await dispatch(
        sendRequest("put", `/users/${currentUser.id}`, { emailNotification })
      );

      dispatch(
        updateEmailNotificationSuccess(responseUser.data.emailNotification)
      );
    } catch (error) {
      dispatch(updateEmailNotificationFailure(error));
    }
  };

export const uploadPassport = (photos) => async (dispatch, getState) => {
  try {
    dispatch(uploadPassportRequest());
    const {
      userInfo: { currentUser },
    } = getState();

    const body = new FormData();
    photos.forEach((photo) => {
      body.append("files", photo);
    });
    const picture = await dispatch(sendRequest("post", "/upload", body));

    if (!picture.data || typeof picture.data === "undefined")
      throw new Error(
        `Error uploading passport photos: ${JSON.stringify(picture)}`
      );

    const responseUser = await dispatch(
      sendRequest("put", `/users/${currentUser.id}`, {
        passportPhotos: picture.data,
        userStatus: "unconfirmed",
      })
    );

    dispatch(
      uploadPassportSuccess({
        response: responseUser.data,
        picture: picture.data,
      })
    );
  } catch (error) {
    dispatch(uploadPassportFailure(error));
  }
};

export const updatePassportStatus = () => async (dispatch, getState) => {
  try {
    dispatch(updatePassportStatusRequest());
    const {
      userInfo: { currentUser },
    } = getState();

    const responseUser = await dispatch(
      sendRequest("get", `/users/${currentUser.id}`)
    );

    dispatch(updateUserSuccess(responseUser.data));
    dispatch(updateUserIdentity(responseUser.data.identity));
    dispatch(updateCompanySuccess(responseUser.data.company));
    dispatch(updatePassportStatusSuccess());
  } catch (error) {
    dispatch(updatePassportStatusFailure(error));
  }
};

export const resetAllErrors = () => async (dispatch) => {
  dispatch(resetError());
};

export const resetUserState = () => async (dispatch) => {
  dispatch(resetState());
};

export default reducer;
