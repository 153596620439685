module.exports = {
  locales: ["en", "nl"],
  defaultLocale: "en",
  pages: {
    "*": ["common", "notification"],
    "/": ["home"],
    "/about": ["about", "home"],
    "/support": ["support"],
    "/terms-and-conditions": ["terms-and-condition"],
    "/privacy-policy": ["privacy-policy"],
    "/onboarding": ["onboarding"],
    "/users": ["onboarding", "insolvency-user"],
    "/[user]": ["onboarding"],
    "/[user]/insolvencies/edit/[insolvency]": [
      "add-insolvency",
      "nda-template",
      "categories-locale",
    ],
    "/[user]/insolvencies/add": [
      "add-insolvency",
      "nda-template",
      "categories-locale",
    ],
    "/[user]/notifications": ["notification"],
    "/insolvencies": ["categories-locale", "nda-template"],
    "/[user]/ndas": ["nda-template"],
    "/insolvencies/[insolvency]": ["categories-locale", "nda-template"],
    "/insolvencies/[insolvency]/categories/[category]": [
      "categories-locale",
      "nda-template",
    ],
    "/insolvencies/[insolvency]/categories/[category]/[subCategory]": [
      "categories-locale",
      "nda-template",
    ],
    "/insolvencies/[insolvency]/[lot]": ["categories-locale", "nda-template"],
    "/insolvencies/[insolvency]/analytics": [
      "categories-locale",
      "view-analytic",
    ],
    "/insolvencies/[insolvency]/users": [
      "categories-locale",
      "insolvency-user",
      "onboarding",
    ],
    "/insolvencies/[insolvency]/dataroom": ["dataroom", "nda-template"],
  },
  loadLocaleFrom: (lang, ns) =>
    fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/${ns}?locale=${lang}`)
      .then((res) => res.json())
      .then((res) => res?.data?.attributes),
  // You can use a dynamic import, fetch, whatever. You should
  // return a Promise with the JSON file.
};
