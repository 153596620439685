import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedNotification: null,
};

const { actions, reducer } = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setSelectedNotification(state, action) {
      state.selectedNotification = action.payload;
    },
    resetNotification(state) {
      state.selectedNotification = null;
    },
  },
});

export const { setSelectedNotification, resetNotification } = actions;

export default reducer;
