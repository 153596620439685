import { createSlice } from "@reduxjs/toolkit";

import apiClient from "../constants/apiClient";

const initialState = {
  token: null,
};

const { actions, reducer } = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetState(state) {
      state.token = initialState.token;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
  },
});

export const { setToken, resetState } = actions;

export const sendRequest =
  (method, url, payload = null, options = {}) =>
  async (dispatch, getState) => {
    try {
      const {
        auth: { token },
      } = getState();

      const optionsFinal = {
        ...options,
        headers: {
          ...options.headers,
          // Add Authorization header only if token exists to prevent 401 Unauthorized errors.
          ...(token != null && { Authorization: `Bearer ${token}` }),
          ...(payload && { "Content-Type": "application/json" }),
        },
      };

      if (method === "post") {
        return apiClient.post(url, payload, optionsFinal);
      }
      if (method === "put") {
        return apiClient.put(url, payload, optionsFinal);
      }
      if (method === "delete") {
        return apiClient.delete(url, optionsFinal);
      }
      return apiClient.get(url, optionsFinal);
    } catch (error) {
      return error;
    }
  };

export const resetAuthState = () => async (dispatch) => {
  dispatch(resetState());
};

export default reducer;
