import { Layout, Spin } from "antd";
import Image from "next/image";

function LoadingScreen() {
  return (
    <Layout
      style={{
        alignContent: "center",
        justifyContent: "center",
        height: "100vh",
        background: "white",
      }}
    >
      <div className="logo" style={{ alignSelf: "center" }}>
        <Image
          priority
          src="/images/logo.svg"
          alt="Bexchange logo"
          width={273}
          height={32}
        />
      </div>
      <Spin style={{ marginTop: 60 }} size="large" />
    </Layout>
  );
}

export default LoadingScreen;
