
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import { useStore, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useIdleTimer } from "react-idle-timer";

import LoadingScreen from "../components/layout/LoadingScreen";

import "../styles/styles.css";
import { wrapper } from "../store";
import { resetUserState } from "../store/userInfo";
import { resetAuthState } from "../store/auth";
import { resetCompanyState } from "../store/userCompany";
import { resetIdentityState } from "../store/userIdentity";
import { resetIDINState } from "../store/iDIN";
import { socket } from "../utils/socket";
import { SWRConfig } from "swr";

// Entry point for the application.
function MyApp({ Component, pageProps }) {
  const store = useStore();
  const router = useRouter();
  const dispatch = useDispatch();

  const handleOnIdle = async () => {
    const { userInfo } = store.getState();
    const timestamp = getLastActiveTime(); // eslint-disable-line
    if (
      timestamp &&
      userInfo.isSignIn &&
      localStorage.getItem("rememberMe") === "false"
    ) {
      localStorage.removeItem("rememberMe");
      await router.replace("/");
      await dispatch(resetAuthState());
      await dispatch(resetUserState());
      await dispatch(resetCompanyState());
      await dispatch(resetIdentityState());
      await dispatch(resetIDINState());
    }
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 60 * 6,
    onIdle: handleOnIdle,
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  return (
    <PersistGate persistor={store.__persistor} loading={<LoadingScreen />}>
      <SWRConfig value={{ provider: () => new Map() }}>
        <Component {...pageProps} />
      </SWRConfig>
    </PersistGate>
  );
}

MyApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.object.isRequired,
};

const __Page_Next_Translate__ = wrapper.withRedux(MyApp);


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  